import React, { useState, useEffect } from "react";

import { fetchMarketingContent } from "../../../firebase/Operations/Marketing";

function HeaderTop() {
  const [Texts, setTexts] = useState([]);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    if (Texts.length > 0) {
      const interval = setInterval(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % Texts.length);
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [Texts]);

  useEffect(() => {
    const fetchMarketingContents = async () => {
      try {
        const response = await fetchMarketingContent();
        setTexts(response.map((content) => content.content));
      } catch (error) {
        console.log("Error fetching marketing contents", error);
      }
    };
    fetchMarketingContents();
  }, []);

  return (
    <div className="relative flex items-center justify-center px-4 py-2 bg-black text-white">
      <div className="font-medium text-center text-xs">
        {Texts.length > 0 ? Texts[currentTextIndex] : "Loading..."}
      </div>
    </div>
  );
}

export default HeaderTop;
