import { collection, doc, getDoc, getDocs, limit, where } from 'firebase/firestore';
import { db } from '../firebase';
import { query, orderBy } from 'firebase/firestore';

export const getProducts = async (userId = null) => {
    try {
        const q = query(collection(db, 'products'), orderBy('productName', 'asc'));
        const querySnapshot = await getDocs(q);
        const products = [];

        querySnapshot.forEach((doc) => {
            const productData = doc.data();
            
            // If it's a limited offer product and user has already purchased it, skip it
            if (userId && 
                productData.limitedOffer && 
                productData.purchasedUsers && 
                productData.purchasedUsers.includes(userId)) {
                return;
            }

            products.push({ ...productData, id: doc.id });
        });

        return products;
    } catch (error) {
        console.error('Error getting products:', error);
        return [];
    }
}

export const getProductsSale = async (userId = null) => {
    try {
        const q = query(
            collection(db, 'products'),
            where('sectionCategory', '==', 'sale')
        );
        
        const querySnapshot = await getDocs(q);
        const products = [];

        querySnapshot.forEach((doc) => {
            const productData = doc.data();
            
            // If it's a limited offer product and user has already purchased it, skip it
            if (userId && 
                productData.limitedOffer && 
                productData.purchasedUsers && 
                productData.purchasedUsers.includes(userId)) {
                return;
            }

            products.push({ ...productData, id: doc.id });
        });

        return products;
    } catch (error) {
        console.error('Error getting sale products:', error);
        return [];
    }
};

export const getProductStockAvailability = async (productId) => {
    const productRef = doc(db, 'products', productId);
    const productSnapshot = await getDoc(productRef);
    if (productSnapshot.exists()) {
        return productSnapshot.data();
    } else {
        throw new Error('Product not found');
    }
};

