import { db } from "../firebase"; 
import { collection, addDoc, updateDoc, doc, getDocs, query, orderBy, deleteDoc, getDoc } from "firebase/firestore";

export const fetchMarketingContent = async () => {
  try {
    const querySnapshot = await getDocs(query(collection(db, "marketingUpdates"), orderBy("timestamp", "desc")));
    const updates = [];
    querySnapshot.forEach((docSnap) => {
      updates.push({ id: docSnap.id, ...docSnap.data() });
    });
    return updates; // Return updates array
  } catch (error) {
    console.error("Error fetching content:", error);
    return [];
  }
};

export const fetchImageUrls = async () => {
  const screenshotsRef = doc(db, "reviews", "screenshots");

  try {
    const docSnap = await getDoc(screenshotsRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const imageUrls = data.imageUrls || []; // Ensure `imageUrls` exists
      return imageUrls;
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching image URLs:", error);
    throw new Error("Failed to fetch image URLs.");
  }
};