import { useState, useEffect } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendPasswordResetEmail,
} from "firebase/auth";
import { db } from "./firebase";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";

const auth = getAuth();

// Authentication Functions
export const SignUpFunction = async (
  email,
  password,
  name,
  phonenumber,
  address,
  city,
  postalcode
) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    // Save additional user details to Firestore
    await setDoc(doc(db, "users", user.uid), {
      name: name,
      email: email,
      phonenumber: phonenumber,
      address: address,
      city: city,
      postalcode: postalcode,
    });

    return {
      success: true,
      message: "User successfully signed up",
      uid: user.uid,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
      code: error.code,
    };
  }
};

export const SignInFunction = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    return {
      success: true,
      message: "User signed in successfully",
      uid: user.uid,
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
      code: error.code,
    };
  }
};

export const SignOutFunction = async () => {
  try {
    await auth.signOut();
    return {
      success: true,
      message: "User signed out successfully",
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
      code: error.code,
    };
  }
};

// Custom Hook for Authentication State
export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (user) => {
        if (user) {
          try {
            const userDocRef = doc(db, "users", user.uid);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
              setCurrentUser({
                ...user,
                ...userDoc.data(),
              });
            } else {
              setCurrentUser(user);
            }
            setError(null);
          } catch (fetchError) {
            setError({
              message: fetchError.message,
              code: fetchError.code,
            });
            setCurrentUser(user);
          }
        } else {
          setCurrentUser(null);
        }
        setLoading(false);
      },
      (authError) => {
        setError({
          message: authError.message,
          code: authError.code,
        });
        setLoading(false);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return { currentUser, loading, error };
};

export const updateUserProfile = async (userId, updateData) => {
  try {
    // Reference to the user's document in the "users" collection
    const userDocRef = doc(db, "users", userId);

    // Update the document with the provided data
    await updateDoc(userDocRef, updateData);

    return {
      success: true,
      message: "User profile updated successfully",
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
      code: error.code,
    };
  }
};

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return {
      success: true,
      message: "Password reset email sent successfully",
    };
  } catch (error) {
    return {
      success: false,
      message: error.message,
      code: error.code,
    };
  }
};
