import React, { useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { HiOutlineMenuAlt4 } from "react-icons/hi";
import { FaSearch, FaShoppingCart, FaImage } from "react-icons/fa";
import Flex from "../../designLayouts/Flex";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getCategories } from "../../../firebase/Operations/Category";
import { getProducts } from "../../../firebase/Operations/Products";

const HeaderBottom = () => {
  const products = useSelector((state) => state.orebiReducer.products);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const ref = useRef();

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      if (ref.current?.contains(e.target)) {
        setShow(true);
      } else {
        setShow(false);
      }
    });
  }, [show, ref]);

  useEffect(() => {
    const getCategories_ = async () => {
      try {
        const response = await getCategories();
        setCategories(response);
      } catch (error) {
        console.error("Error getting categories:", error);
      }
    };
    getCategories_();
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const [products_, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  // eslint-disable-next-line
  const [showSearchBar, setShowSearchBar] = useState(false);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const fetchAllProducts = async () => {
      try {
        const response = await getProducts();
        setProducts(response);
        setFilteredProducts(response);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchAllProducts();
  }, []);

  useEffect(() => {
    const filtered = products_.filter((item) =>
      item.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredProducts(filtered);
  }, [searchQuery, products_]);

  const HandleSelectCategory = (category) => {
    navigate(`/category/${category}`);
    // refresh the page
    window.location.reload();
  };

  return (
    <div className="w-full bg-[#F5F5F3] relative">
      <div className="max-w-container mx-auto">
        <Flex className="flex flex-col lg:flex-row items-start lg:items-center justify-between w-full px-4 pb-4 lg:pb-0 h-full lg:h-24">
          <div
            onClick={() => setShow(!show)}
            ref={ref}
            className="flex h-14 cursor-pointer items-center gap-2 text-primeColor"
          >
            <HiOutlineMenuAlt4 className="w-5 h-5" />
            <p className="text-[14px] font-normal">Shop by Category</p>

            {show && (
              <motion.ul
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="absolute top-[5em] z-50 bg-primeColor w-auto text-[#767676] h-auto p-4 pb-6"
              >
                {categories.map((category) => (
                  <li
                    key={category.categoryId}
                    onClick={() => HandleSelectCategory(category.categoryId)}
                    className="text-gray-400 uppercase px-4 py-1 border-b-[1px] border-b-gray-400  hover:border-b-white hover:text-white duration-300 cursor-pointer"
                  >
                    {category.CategoryName}
                  </li>
                ))}
              </motion.ul>
            )}
          </div>
          <div className="relative w-full lg:w-[600px] h-[50px] text-base text-primeColor bg-white flex items-center gap-2 justify-between px-6 rounded-xl">
            <input
              className="flex-1 h-full outline-none placeholder:text-[#C4C4C4] placeholder:text-[14px]"
              type="text"
              onChange={handleSearch}
              value={searchQuery}
              placeholder="Search your products here"
            />
            <FaSearch className="w-5 h-5" />
            {searchQuery && (
              <div
                className={`px-5 py-2 w-full mx-auto h-96 bg-white top-16 absolute left-0 z-50 overflow-y-scroll shadow-2xl scrollbar-hide cursor-pointer`}
              >
                {searchQuery &&
                  filteredProducts.map((item) => (
                    <div
                      onClick={() =>
                        navigate(
                          `/product/${item.productName
                            .toLowerCase()
                            .split(" ")
                            .join("")}`,
                          {
                            state: {
                              item: {
                                _id: item.id,
                                img: item.image,
                                productName: item.productName,
                                originalProductName: item.productName,
                                price: item.costPrice,
                                des: item.shortDescription,
                                mrp: item.mrp,
                                stock: item.stock,
                                sellingPrice: item.sellingPrice,
                                weightUnit: item.weightUnit,
                                gst: item.tax,
                                shippingAmount: item.shippingAmount,
                                selectedBoxes: item.selectedBoxes,
                                selectedBoxesWithstock:
                                  item.selectedBoxesWithstock,
                                weightAmount: item.weightAmount,
                              },
                            },
                          }
                        ) &
                        setShowSearchBar(true) &
                        setSearchQuery("")
                      }
                      key={item.id}
                      className="px-2 max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-3"
                    >
                      {item.image ? (
                        <img
                          className="w-24"
                          src={item.image}
                          alt="productImg"
                        />
                      ) : (
                        <FaImage className="w-24 h-24 text-gray-400" />
                      )}
                      <div className="flex flex-col gap-1">
                        <p className="font-semibold text-lg">
                          {item.productName}
                        </p>
                        <p className="text-xs">{item.des}</p>
                        <p className="text-sm">
                          Price:{" "}
                          <span className="text-primeColor font-semibold">
                            ₹{item.sellingPrice}
                          </span>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="flex gap-4 mt-2 lg:mt-0 items-center pr-6 cursor-pointer relative">
            <Link to="/cart">
              <div className="relative">
                <div className="flex gap-3 text-medium items-center justify-center">
                  <FaShoppingCart />
                </div>
                <span className="absolute font-titleFont top-3 -right-2 text-xs w-4 h-4 flex items-center justify-center rounded-full bg-primeColor text-white">
                  {products.length > 0 ? products.length : 0}
                </span>
              </div>
            </Link>
          </div>
        </Flex>
      </div>
    </div>
  );
};

export default HeaderBottom;
