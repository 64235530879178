import React from "react";
import loader from "./loader.webp"; // Ensure the correct path

const Loader = () => {
  return (
    <div className="flex items-center justify-center w-full h-full fixed inset-0 bg-white bg-opacity-75 z-50 flex-col">
      <div className="relative flex flex-col items-center">
        {/* Rotating Circle Around Logo */}
        <div className="relative flex items-center justify-center">
          <img
            src={loader}
            alt="Lovely Shoppe Loader"
            className="z-10 w-16 h-16 rounded-full"
          />
          <div
            className="absolute w-28 h-28 rounded-full border-2 border-transparent animate-spin"
            style={{
              borderTopColor: "#E91E63", // Pink
              borderRightColor: "#fff", // Pink
              borderBottomColor: "#E91E63", // Pink
              borderLeftColor: "#fff", // Pink
            }}
          ></div>
        </div>
      </div>
      <p className="text-center font-bodyFont text-black mt-10 text-2xl">
        Lovely <span className="text-pink-500">Shoppe</span>
      </p>
    </div>
  );
};

export default Loader;
