import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: [],
  products: [],
  productsTest: [],
  wishList: [],
  orderedProducts: [],
};

export const orebiSlice = createSlice({
  name: "orebi",
  initialState,
  reducers: {
    addWishList: (state, action) => {
      const list = state.wishList.find(
        (item) => item._id === action.payload._id
      );
      if (list) {
        return;
      }
      state.wishList.push(action.payload);
    },
    removeWishList: (state, action) => {
      state.wishList = state.wishList.filter(
        (item) => item._id !== action.payload
      );
    },
    resetWishList: (state) => {
      state.wishList = [];
    },
    addToCart: (state, action) => {
      const { _id, selectedVariant } = action.payload;
      const existingItemIndex = state.products.findIndex(
        (item) => item._id === _id && item.selectedVariant === selectedVariant
      );

      if (existingItemIndex >= 0) {
        // Update quantity for existing variant
        state.products[existingItemIndex].quantity += action.payload.quantity;
      } else {
        // Add new item with variant
        state.products.push(action.payload);
      }
    },
    addToCartTest: (state, action) => {
      const { verbsose } = action.payload;
      if (verbsose) {
        // alert('Working in Verbose Mode');
        const { variants } = action.payload;
        const item = state.productsTest.find(
          (item) => item._id === action.payload._id
        );
        if (item) {
          const existingVariant = item.variants.find(
            (v) => v.variant === variants[0].variant
          );
          if (existingVariant) {
            // alert('Item Already Exists');
            if (
              existingVariant.quantity + variants[0].quantity >
              action.payload.stock
            ) {
              return;
            }
            existingVariant.quantity += variants[0].quantity;
          } else {
            // alert('Item Not Already Exists');
            if (variants[0].quantity > action.payload.stock) {
              return;
            }
            item.variants.push({ ...variants[0] });
          }
        } else {
          // alert('Item Not Found');
          state.productsTest.push({
            _id: action.payload._id,
            name: action.payload.name,
            quantity: action.payload.quantity,
            image: action.payload.image,
            badge: action.payload.badge,
            price: action.payload.price,
            mrp: action.payload.mrp,
            weightUnit: action.payload.weightUnit,
            gst: action.payload.gst,
            stock: action.payload.stock,
            shippingAmount: action.payload.shippingAmount,
            weightAmount: action.payload.weightAmount,
            variants: [...variants],
            stockValue: action.payload.stockValue,
          });
        }
        return;
      } else {
        const item = state.productsTest.find(
          (item) => item._id === action.payload._id
        );
        if (item) {
          item.quantity += action.payload.quantity;
        } else {
          state.productsTest.push(action.payload);
        }
        return;
      }
      // // Find the existing product by _id
      // const existingItem = state.productsTest.find((item) => item._id === _id);

      // if (existingItem) {
      //   // If the product has variants
      //   if (variants?.length > 0) {
      //     variants.forEach((newVariant) => {
      //       // Check if the variant exists for the product
      //       const existingVariant = existingItem.variants?.find(
      //         (v) => v.variant === newVariant.variant
      //       );

      //       if (existingVariant) {
      //         // Variant exists, update the quantity
      //         if (existingVariant.quantity + newVariant.quantity > stock) {
      //           console.log(`Cannot add more than available stock for ${newVariant.variant}!`);
      //           return;  // Prevent adding more than available stock
      //         }
      //         existingVariant.quantity += newVariant.quantity;
      //         console.log(`Updated Quantity for Variant (${newVariant.variant}):`, existingVariant.quantity);
      //       } else {
      //         // Variant does not exist, add as a new variant
      //         if (newVariant.quantity > stock) {
      //           console.log(`Selected quantity exceeds stock for ${newVariant.variant}!`);
      //           return;  // Prevent adding more than available stock
      //         }
      //         existingItem.variants.push({ ...newVariant });
      //         console.log(`Added New Variant (${newVariant.variant}):`, newVariant);
      //       }
      //     });
      //   } else {
      //     // Product doesn't have variants, update the product quantity directly
      //     if (existingItem.quantity + action.payload.quantity > stock) {
      //       console.log(`Cannot add more than available stock for the product!`);
      //       return;  // Prevent adding more than available stock
      //     }
      //     existingItem.quantity += action.payload.quantity;
      //     console.log("Updated Quantity for Product:", existingItem.quantity);
      //   }
      // } else {
      //   // If the product does not exist in the cart, add it with variants (or no variants)
      //   if (variants?.length > 0) {
      //     state.productsTest.push({
      //       _id,
      //       variants,
      //       quantity: 0,  // Clear the top-level quantity as variants handle it
      //     });
      //     console.log("Added New Product with Variants:", { _id, variants });
      //   } else {
      //     // No variants, add the product directly
      //     if (action.payload.quantity > stock) {
      //       console.log(`Selected quantity exceeds stock for the product!`);
      //       return;  // Prevent adding more than available stock
      //     }
      //     state.productsTest.push({
      //       ...action.payload,
      //       variants: [],
      //     });
      //     console.log("Added New Product:", action.payload);
      //   }
      // }

      // console.log("Updated Cart:", state.productsTest);
    },
    increaseQuantity: (state, action) => {
      const { verbose, variantType } = action.payload;
      if (verbose) {
        // alert('Working in Verbose Mode');
        const item = state.productsTest.find(
          (item) => item._id === action.payload._id
        );
        if (item) {
          const variant = item.variants.find((v) => v.variant === variantType);
          if (variant) {
            // alert('Incrementing Quantity');
            variant.quantity++;
          }
        }
      } else {
        // alert('Working in Non Verbose Mode');
        const item = state.productsTest.find(
          (item) => item._id === action.payload._id
        );
        if (item) {
          item.quantity++;
        }
      }
    },
    drecreaseQuantity: (state, action) => {
      const { verbose, variantType } = action.payload;
      if (verbose) {
        // alert('Working in Verbose Mode');
        const item = state.productsTest.find(
          (item) => item._id === action.payload._id
        );
        if (item) {
          const variant = item.variants.find((v) => v.variant === variantType);
          if (variant) {
            // alert('Decrementing Quantity');
            if (variant.quantity === 1) {
              variant.quantity = 1;
            } else {
              variant.quantity--;
            }
          }
        }
      } else {
        const item = state.productsTest.find(
          (item) => item._id === action.payload._id
        );
        if (item.quantity === 1) {
          item.quantity = 1;
        } else {
          item.quantity--;
        }
      }
    },
    deleteItem: (state, action) => {
      const { verbose, variantType } = action.payload;
      if (verbose) {
        const item = state.productsTest.find(
          (item) => item._id === action.payload._id
        );
        if (item) {
          const index = item.variants.findIndex(
            (v) => v.variant === variantType
          );
          if (index !== -1) {
            item.variants.splice(index, 1);
          }
        }
        if (item.variants.length === 0) {
          state.productsTest = state.productsTest.filter(
            (item) => item._id !== action.payload._id
          );
        }
      } else {
        state.productsTest = state.productsTest.filter(
          (item) => item._id !== action.payload._id
        );
      }
    },
    resetCart: (state) => {
      state.productsTest = [];
    },
    resetOrderedProducts: (state) => {
      state.orderedProducts = [];
    },
    AddUserInfo: (state, action) => {
      state.userInfo.push(action.payload);
    },
    CreateOrderProducts: (state, action) => {
      state.orderedProducts.push(action.payload);
    },
    resetuserInfo: (state) => {
      state.userInfo = [];
    },
    adjustCartItemQuantity: (state, action) => {
      const { productId, quantity, variantType } = action.payload;
      const item = state.productsTest.find((item) => item._id === productId);

      if (item) {
        if (variantType && item.variants?.length > 0) {
          const variant = item.variants.find((v) => v.variant === variantType);
          if (variant) {
            variant.quantity = quantity;
          }
        } else {
          item.quantity = quantity;
        }
      }
    },
  },
});

export const {
  addToCart,
  addWishList,
  increaseQuantity,
  drecreaseQuantity,
  deleteItem,
  resetCart,
  resetWishList,
  removeWishList,
  AddUserInfo,
  CreateOrderProducts,
  addToCartTest,
  resetOrderedProducts,
  resetuserInfo,
  adjustCartItemQuantity,
} = orebiSlice.actions;
export default orebiSlice.reducer;
