import React, { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const PasswordInput = ({
  value,
  onChange,
  placeholder = "Enter password",
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col gap-0.5">
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
        />
        <span
          onClick={toggleShowPassword}
          className="absolute right-3 top-2 text-gray-500 cursor-pointer"
        >
          {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
        </span>
      </div>
      {error && (
        <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
          <span className="font-bold italic mr-1">!</span>
          {error}
        </p>
      )}
    </div>
  );
};

export default PasswordInput;
