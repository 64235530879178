  import { collection, getDocs } from 'firebase/firestore';
  import { db } from '../firebase';

  export const getCategories = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "categories"));
      const categories = querySnapshot.docs.map((doc) => ({
        categoryId: doc.id, 
        ...doc.data(), 
      }));
      return categories;
    } catch (error) {
      console.error("Error getting categories:", error);
    }
  };
