import React, { useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { sendPasswordResetEmail } from "firebase/auth";
import { getAuth } from "firebase/auth";

import PasswordInput from "../../components/Password/PasswordInput";
import { SignInFunction } from "../../firebase/Auth";
import { AddUserInfo } from "../../redux/orebiSlice";
import shoppeLogo from "./shoplogo.webp";

// Add this new component for the forgot password modal
const ForgotPasswordModal = ({ isOpen, onClose, onSubmit }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setEmailSent(true);
      setError("");
    } catch (error) {
      setError("Email not found or invalid");
      toast.error("Error sending reset email");
    }
  };

  const maskEmail = (email) => {
    const [username, domain] = email.split("@");
    return `${username.slice(0, 3)}***@${domain}`;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg w-96 max-w-[90vw]">
        {!emailSent ? (
          <>
            <h2 className="text-2xl font-bold mb-4">Reset Password</h2>
            <form onSubmit={handleSubmit}>
              <div>
                <p className="mb-4 text-gray-600">
                  Enter your email address and we'll send you instructions to
                  reset your password.
                </p>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="w-full p-2.5 border rounded mb-4 focus:outline-none focus:ring-2 focus:ring-primeColor"
                />
                {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
              </div>

              <div className="flex justify-end gap-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-primeColor text-white rounded hover:bg-black transition duration-300"
                >
                  Send Reset Link
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="text-center">
            <div className="mb-6">
              <svg
                className="w-16 h-16 text-green-500 mx-auto"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h2 className="text-2xl font-bold mb-4">Check Your Email</h2>
            <p className="mb-4 text-gray-600">
              We've sent password reset instructions to:
              <br />
              <span className="font-semibold">{maskEmail(email)}</span>
            </p>
            <p className="text-sm text-gray-500 mb-6">
              If you don't see the email, check your spam folder.
            </p>
            <button
              onClick={onClose}
              className="px-6 py-2 bg-primeColor text-white rounded hover:bg-black transition duration-300"
            >
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ============= Initial State Start here =============
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // ============= Initial State End here ===============

  // ============= Error Msg Start here =================
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");

  // ============= Error Msg End here ===================

  // ============= Event Handler Start here =============
  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };
  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };

  // ============= Event Handler End here ===============
  const handleSignUp = async (e) => {
    e.preventDefault();

    if (!email) {
      setErrEmail("Enter your email");
    }

    if (!password) {
      setErrPassword("Create a password");
    }
    if (email && password) {
      const response = await SignInFunction(email, password);
      if (!response) {
        toast.error("Invalid email or password");
        setTimeout(() => {
          navigate("/signup");
        }, 1000);
      }
      if (!response.success) {
        setErrEmail("Invalid email or password");
        setErrPassword("Invalid email or password");
      } else {
        toast.success("Successfully Logged In");
        dispatch(AddUserInfo({ uid: response.uid }));
        navigate("/");
      }
      setEmail("");
      setPassword("");
    }
  };

  const [showForgotPassword, setShowForgotPassword] = useState(false);

  return (
    <div className="w-full h-screen flex items-center justify-center">
      <ToastContainer />
      <div className="w-1/2 hidden lgl:inline-flex h-full text-white">
        <div className="w-[450px] h-full bg-primeColor px-10 flex flex-col gap-6 justify-center">
          <Link to="/">
            <img src={shoppeLogo} alt="logoImg" className="w-16" />
          </Link>
          <div className="flex flex-col gap-1 -mt-1">
            <h1 className="font-titleFont text-xl font-medium">
              Stay sign in for more
            </h1>
            <p className="text-base">When you sign in, you are with us!</p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Get started fast with Lovely shoppe
              </span>
              <br />
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Access all Lovely shoppe services
              </span>
              <br />
            </p>
          </div>
          <div className="w-[300px] flex items-start gap-3">
            <span className="text-green-500 mt-1">
              <BsCheckCircleFill />
            </span>
            <p className="text-base text-gray-300">
              <span className="text-white font-semibold font-titleFont">
                Trusted by online Shoppers
              </span>
              <br />
            </p>
          </div>
          <div className="flex items-center justify-between mt-10">
            <Link to="/">
              <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
                © Lovely shoppe
              </p>
            </Link>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Terms
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Privacy
            </p>
            <p className="text-sm font-titleFont font-semibold text-gray-300 hover:text-white cursor-pointer duration-300">
              Security
            </p>
          </div>
        </div>
      </div>
      <div className="w-full lgl:w-1/2 h-full">
        <form className="w-full lgl:w-[450px] h-screen flex items-center justify-center">
          <div className="px-6 py-4 w-full h-[90%] flex flex-col justify-center overflow-y-scroll scrollbar-thin scrollbar-thumb-primeColor">
            <h1 className="font-titleFont decoration-[1px] font-semibold text-3xl mdl:text-4xl mb-4">
              Log in to<span className="text-pink-400"> Lovely Shoppe</span>
            </h1>
            <div className="flex flex-col gap-3">
              {/* Email */}
              <div className="flex flex-col gap-.5">
                <p className="font-titleFont text-base font-semibold text-gray-600">
                  Email
                </p>
                <input
                  onChange={handleEmail}
                  value={email}
                  className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                  type="email"
                  placeholder="Enter your email address"
                />
                {errEmail && (
                  <p className="text-sm text-red-500 font-titleFont font-semibold px-4">
                    <span className="font-bold italic mr-1">!</span>
                    {errEmail}
                  </p>
                )}
              </div>
              {/* Password */}
              <div>
                <p className="font-titleFont text-base font-semibold text-gray-600">
                  Password
                </p>
                <PasswordInput
                  value={password}
                  onChange={handlePassword}
                  placeholder="Create a secure password"
                  error={errPassword}
                />
                <button
                  type="button"
                  onClick={() => setShowForgotPassword(true)}
                  className="text-sm text-blue-600 hover:text-blue-800 mt-1"
                >
                  Forgot Password?
                </button>
              </div>

              <button
                onClick={handleSignUp}
                className="bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer w-full text-base font-medium h-10 rounded-md  duration-300"
              >
                Log In
              </button>
              <p className="text-sm text-center font-titleFont font-medium">
                Don't have an Account?{" "}
                <Link to="/signup">
                  <button className="ml-1 bg-white border border-primeColor hover:bg-primeColor text-primeColor hover:text-white px-4 py-1 rounded-md text-sm font-medium transition duration-300 shadow-sm">
                    Sign up
                  </button>
                </Link>
              </p>
            </div>
          </div>
        </form>
      </div>

      {/* Forgot Password Modal */}
      <ForgotPasswordModal
        isOpen={showForgotPassword}
        onClose={() => setShowForgotPassword(false)}
        onSubmit={(email) => {
          // Handle password reset logic
        }}
      />
    </div>
  );
};

export default SignIn;
