// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAOgIfaWiH1hebxtRANvkaDjDJHH9Ty_lk",
//   authDomain: "lovelyshoppingtest.firebaseapp.com",
//   projectId: "lovelyshoppingtest",
//   storageBucket: "lovelyshoppingtest.firebasestorage.app",
//   messagingSenderId: "648257175017",
//   appId: "1:648257175017:web:15a1fe3bf83a25ba409f78",
//   measurementId: "G-T7PHFN821K"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCEgVUa8a7RYLc135iuTOJ1f9XD6JuB6OM",
  authDomain: "lovelyshopee-e283d.firebaseapp.com",
  projectId: "lovelyshopee-e283d",
  storageBucket: "lovelyshopee-e283d.firebasestorage.app",
  messagingSenderId: "608162255216",
  appId: "1:608162255216:web:5155ad4eac84aae9bbbfb5",
  measurementId: "G-MT24MPPYB6"
};
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db };