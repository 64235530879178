import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { RiShoppingCart2Fill } from "react-icons/ri";
import { MdSwitchAccount } from "react-icons/md";
import { useSelector } from "react-redux";
import { Heart } from "lucide-react";

const SpecialCase = () => {
  const navigate = useNavigate();
  const products = useSelector((state) => state.orebiReducer.productsTest);
  const wishlist = useSelector((state) => state.orebiReducer.wishList);
  const userUid = useSelector((state) => state.orebiReducer.userInfo);

  const HandleUser = () => {
    if (userUid.length === 0) {
      navigate("/signin");
    } else {
      navigate("/profile");
    }
  };

  return (
    <>
      {/* Desktop Navigation - Right Side */}
      <div className="fixed right-2 z-20 hidden md:flex flex-col gap-2">
        {/* Profile Button */}
        <div onClick={HandleUser}>
          <div className="bg-white w-16 h-[70px] rounded-md flex flex-col gap-1 text-[#33475b] justify-center items-center shadow-testShadow overflow-x-hidden group cursor-pointer">
            <div className="flex justify-center items-center">
              <MdSwitchAccount className="text-2xl -translate-x-12 group-hover:translate-x-3 transition-transform duration-200" />
              <MdSwitchAccount className="text-2xl -translate-x-3 group-hover:translate-x-12 transition-transform duration-200" />
            </div>
            <p className="text-xs font-semibold font-titleFont">Profile</p>
          </div>
        </div>

        {/* Cart Button */}
        <Link to="/cart">
          <div className="bg-white w-16 h-[70px] rounded-md flex flex-col gap-1 text-[#33475b] justify-center items-center shadow-testShadow overflow-x-hidden group cursor-pointer relative">
            <div className="flex justify-center items-center">
              <RiShoppingCart2Fill className="text-2xl -translate-x-12 group-hover:translate-x-3 transition-transform duration-200" />
              <RiShoppingCart2Fill className="text-2xl -translate-x-3 group-hover:translate-x-12 transition-transform duration-200" />
            </div>
            <p className="text-xs font-semibold font-titleFont">My Cart</p>
            {products.length > 0 && (
              <p className="absolute top-1 right-2 bg-primeColor text-white text-xs w-4 h-4 rounded-full flex items-center justify-center font-semibold">
                {products.length}
              </p>
            )}
          </div>
        </Link>

        {/* Wishlist Button */}
        <Link to="/wishlist">
          <div className="bg-white w-16 h-[70px] rounded-md flex flex-col gap-1 text-[#33475b] justify-center items-center shadow-testShadow overflow-x-hidden group cursor-pointer relative">
            <div className="flex justify-center items-center">
              <Heart className="text-2xl -translate-x-12 group-hover:translate-x-3 transition-transform duration-200" />
              <Heart className="text-2xl -translate-x-3 group-hover:translate-x-12 transition-transform duration-200" />
            </div>
            <p className="text-xs font-semibold font-titleFont">Wish List</p>
            {wishlist.length > 0 && (
              <p className="absolute top-1 right-2 bg-primeColor text-white text-xs w-4 h-4 rounded-full flex items-center justify-center font-semibold">
                {wishlist.length}
              </p>
            )}
          </div>
        </Link>
      </div>

      {/* Mobile Navigation - Right Side */}
      <div className="fixed top-52 right-2 z-20 md:hidden flex flex-col gap-2">
        {/* Profile */}
        <div
          onClick={HandleUser}
          className="bg-white w-16 h-[70px] rounded-md flex flex-col gap-1 text-[#33475b] justify-center items-center shadow-testShadow cursor-pointer relative"
        >
          <MdSwitchAccount className="text-2xl" />
          <p className="text-xs font-semibold font-titleFont">Profile</p>
        </div>

        {/* Cart */}
        <Link to="/cart" className="relative">
          <div className="bg-white w-16 h-[70px] rounded-md flex flex-col gap-1 text-[#33475b] justify-center items-center shadow-testShadow cursor-pointer">
            <div className="relative">
              <RiShoppingCart2Fill className="text-2xl" />
              {products.length > 0 && (
                <p className="absolute -top-2 -right-2 bg-primeColor text-white text-xs w-4 h-4 rounded-full flex items-center justify-center font-semibold">
                  {products.length}
                </p>
              )}
            </div>
            <p className="text-xs font-semibold font-titleFont">Buy Now</p>
          </div>
        </Link>

        {/* Wishlist */}
        <Link to="/wishlist" className="relative">
          <div className="bg-white w-16 h-[70px] rounded-md flex flex-col gap-1 text-[#33475b] justify-center items-center shadow-testShadow cursor-pointer">
            <div className="relative">
              <Heart className="text-2xl" />
              {wishlist.length > 0 && (
                <p className="absolute -top-2 -right-2 bg-primeColor text-white text-xs w-4 h-4 rounded-full flex items-center justify-center font-semibold">
                  {wishlist.length}
                </p>
              )}
            </div>
            <p className="text-xs font-semibold font-titleFont">Wish List</p>
          </div>
        </Link>
      </div>
    </>
  );
};

export default SpecialCase;
