import React from "react";
import { Home, Mail, Phone } from "lucide-react";
import { FaFacebook, FaYoutube, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { AiOutlineCopyright } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import shoppeLogo from "./assets/shoplogo.webp";

const FooterLink = ({ to, children }) => {
  const navigate = useNavigate();
  return (
    <li
      onClick={() => navigate(to)}
      className="font-titleFont text-base text-lightText hover:text-black hover:underline cursor-pointer duration-300"
    >
      {children}
    </li>
  );
};

const Footer = () => {
  const email = "lovelyshoppekovai@gmail.com";

  return (
    <footer className="w-full bg-[#F5F5F3] border-t border-gray-300">
      <div className="max-w-container mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-6 px-4 gap-6 py-8">
        {/* Logo and Info */}
        <div className="col-span-2">
          <div className="flex flex-col gap-4">
            <img
              src={shoppeLogo}
              alt="Lovely Shoppe Logo"
              className="w-16 h-16"
              width="96"
              height="96"
            />
            <p className="font-bodyFont text-2xl">Lovely Shoppe Kovai</p>
            <p className="text-base font-titleFont text-lightText">
              Lovely Shoppe offers a delightful range of curated products,
              ensuring quality and value for every purchase.
            </p>
            {/* Social Links */}
            <div className="flex items-center gap-3">
              <a
                href="https://www.youtube.com/@lovelyshoppecoimbatore8060"
                target="_blank"
                rel="noreferrer"
                aria-label="YouTube"
              >
                <FaYoutube className="w-8 h-8 text-red-600" />
              </a>
              <a
                href="https://www.facebook.com/share/d7ep4cxGNDaAaBwu/?mibextid=wwXIfr"
                target="_blank"
                rel="noreferrer"
                aria-label="Facebook"
              >
                <FaFacebook className="w-8 h-8 text-blue-600" />
              </a>
              <a
                href="https://www.instagram.com/the_lovelyshoppecbe/profilecard/?igsh=dzhhNWRyc3pqNTd2"
                target="_blank"
                rel="noreferrer"
                aria-label="Instagram"
              >
                <FaInstagram className="w-8 h-8 text-pink-600" />
              </a>
              <a
                href="https://wa.me/8122882342"
                target="_blank"
                rel="noreferrer"
                aria-label="WhatsApp"
              >
                <FaWhatsapp className="w-8 h-8 text-green-600" />
              </a>
            </div>
          </div>
        </div>

        {/* Navigation Links */}
        <div>
          <h3 className="text-lg font-bold mb-4">LINKS</h3>
          <ul className="space-y-2">
            <FooterLink to="/">Home</FooterLink>
            <FooterLink to="/cart">Cart</FooterLink>
            <FooterLink to="/wishlist">Wishlist</FooterLink>
            <FooterLink to="/myorders">Checkout</FooterLink>
            <FooterLink to="/profile">My Account</FooterLink>
            <FooterLink to="/myorders">My Order</FooterLink>
          </ul>
        </div>

        {/* Policy Links */}
        <div>
          <h3 className="text-lg font-bold mb-4">POLICY</h3>
          <ul className="space-y-2">
            <FooterLink to="/returnpolicy">Return Policy</FooterLink>
            <FooterLink to="/termsandconditions">Terms & Conditions</FooterLink>
            <FooterLink to="/privacyandpolicy">Privacy Policy</FooterLink>
          </ul>
        </div>

        {/* Contact Details */}
        <div className="col-span-2">
          <h3 className="text-lg font-bold mb-4">CONTACT</h3>
          <ul className="space-y-2">
            <li className="flex items-center gap-2 text-lightText hover:text-black cursor-pointer">
              <Phone className="w-4 h-4" /> <span>+91 7305879414</span>
            </li>
            <li className="flex items-start gap-2 text-lightText hover:text-black cursor-pointer">
              <Home className="w-4 h-4 mt-1" />
              <a
                className="flex flex-col text-sm"
                href="https://www.google.com/maps/place/Lovely+beads%26charms/@10.9480546,76.889682,17z/data=!3m1!4b1!4m6!3m5!1s0x3ba85d29d59552e3:0xa1a7b6be5910bb1c!8m2!3d10.9480493!4d76.8922569!16s%2Fg%2F11vx6fbktz?authuser=0&entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noreferrer"
              >
                <span>Site no 1, Murugan Nagar Phase 2</span>
                <span>
                  Theethipalayam, Coimbatore, Tamil Nadu, India - 641010
                </span>
              </a>
            </li>
            <li className="flex items-center gap-2 text-lightText hover:text-black cursor-pointer">
              <Mail className="w-4 h-4" />
              <a href={`mailto:${email}`} className="hover:text-black">
                {email}
              </a>
            </li>
          </ul>
        </div>
      </div>

      {/* Copyright */}
      <div className="w-full bg-[#F5F5F3] border-t pt-3 text-center py-4">
        <p className="text-sm text-lightText flex items-center justify-center">
          <AiOutlineCopyright className="mr-1" />
          <span>Copyright 2025 | Lovely Shoppe | All Rights Reserved</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
