import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Import Components
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import HeaderTop from "./components/home/Header/HeaderTop";
import Loader from "./components/Loader/Loader";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Footer from "./components/home/Footer/Footer";
import ScrollToTop from "./components/Scrolling/ScrollToTop";

// Lazy Loaded Pages
const Home = lazy(() => import("./pages/Home/Home"));
const Shop = lazy(() => import("./pages/Shop/Shop"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const Offer = lazy(() => import("./pages/Offer/Offer"));
const ProductDetails = lazy(() =>
  import("./pages/ProductDetails/ProductDetails")
);
const Cart = lazy(() => import("./pages/Cart/Cart"));
const MyOrders = lazy(() => import("./pages/myorders/MyOrders"));
const Coupons = lazy(() => import("./pages/Coupons/Coupons"));
const WishList = lazy(() => import("./pages/Wishlist/WishList"));
const Payment = lazy(() => import("./pages/payment/Payment"));
const FilteredPage = lazy(() =>
  import("./pages/FilteredProductsCommon/FilteredProducts")
);
const TermsAndConditions = lazy(() =>
  import("./pages/terms&condition/termsandcondition")
);
const PrivacyandPolicy = lazy(() => import("./pages/privacyandpolicy/privacy"));
const Returnpolicy = lazy(() => import("./pages/returnpolicy/returnpolicy"));
const Profilepage = lazy(() => import("./pages/Profile/useraccount"));
const Addressedit = lazy(() => import("./pages/Profile/addressedit"));

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <div className="overflow-hidden bg-white">
        <HeaderTop />
        <Header />
        <HeaderBottom />
        <SpecialCase />
        <ToastContainer />
        <div className="flex-1">
          {loading ? (
            <div className="min-h-screen mt-40">
              <Loader />
            </div>
          ) : (
            <Suspense
              fallback={
                <div className="min-h-screen mt-40">
                  <Loader />
                </div>
              }
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/offer" element={<Offer />} />
                <Route path="/product/:_id" element={<ProductDetails />} />
                <Route path="/cart" element={<Cart />} />
                <Route path="/myorders" element={<MyOrders />} />
                <Route path="/wishlist" element={<WishList />} />
                <Route path="/paymentgateway" element={<Payment />} />
                <Route path="/category/:category" element={<FilteredPage />} />
                <Route
                  path="/termsandconditions"
                  element={<TermsAndConditions />}
                />
                <Route
                  path="/privacyandpolicy"
                  element={<PrivacyandPolicy />}
                />
                <Route path="/returnpolicy" element={<Returnpolicy />} />
                <Route path="/profile" element={<Profilepage />} />
                <Route path="/coupons" element={<Coupons />} />
                <Route path="/address" element={<Addressedit />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/signin" element={<SignIn />} />
              </Routes>
            </Suspense>
          )}
          <Footer />
        </div>
      </div>
    </Router>
  );
};

export default App;
